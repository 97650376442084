<template>
  <a-form
    :label-col="{ span: 7 }"
    :wrapper-col="{ span: 16 }"
    :model="form"
    :rules="rules"
    ref="formRef"
  >
    <a-form-item
      label="名称"
      name="promoteName"
      required
      :labelCol="{ span: 2, offset: 1 }"
    >
      <a-input v-model:value="form.promoteName" placeholder="请输入名称" />
    </a-form-item>
    <a-form-item
      label="内容"
      name="promoteUrlType"
      :labelCol="{ span: 2, offset: 1 }"
    >
      <a-select v-model:value="form.promoteUrlType" placeholder="请选择">
        <a-select-option value="registForm">注册表单</a-select-option>
        <a-select-option value="question">问卷</a-select-option>
        <a-select-option value="live">直播</a-select-option>
        <a-select-option value="website">网站</a-select-option>
        <a-select-option value="resource">资料</a-select-option>
        <a-select-option value="publicNumber">公众号</a-select-option>
        <!--    暂时隐藏小程序    -->
        <!--        <a-select-option value="applet">小程序</a-select-option>-->
      </a-select>
    </a-form-item>
    <a-form-item
      label="链接"
      name="promoteUrl"
      required
      :labelCol="{ span: 2, offset: 1 }"
    >
      <a-input v-model:value="form.promoteUrl" placeholder="请输入链接" />
    </a-form-item>
    <a-row>
      <a-col :push="3">
        <a-space style="color: #9d9d9d">
          创建后，请至“推广配置”设置链接式推广-分享设置
        </a-space>
      </a-col>
    </a-row>
    <br />
  </a-form>
  <a-space>
    <a-button type="primary" @click="save">保存</a-button>
    <a-button @click="closeModal">取消</a-button>
  </a-space>
</template>
<script>
import { reactive, ref } from 'vue';
import promoteApi from '@/api/promote';
import { Space, Form, Row, Col, message, Select } from 'ant-design-vue';
export default {
  components: {
    ASpace: Space,
    ARow: Row,
    ACol: Col,
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
  },
  setup(props, context) {
    const formRef = ref();
    const form = reactive({
      promoteName: '',
      promoteUrl: '',
      promoteType: 3,
      enabled: 0,
      promoteUrlType: null,
    });
    const required = { required: true, message: '不能为空', trigger: 'blur' };
    const rules = {
      promoteName: [required, { max: 50, message: '不可超过50字' }],
      promoteUrl: [
        required,
        { type: 'url', message: '请输入正确的URL地址，以http或者https开头' },
      ],
      // promoteUrlType: [required],
    };
    const save = () => {
      formRef.value.validate().then(() => {
        promoteApi.promoteAdd('', form).then(() => {
          message.success('操作成功');
          context.emit('seveOnCloseModal', false);
        });
        closeModal();
      });
    };
    const closeModal = () => {
      context.emit('closeModal', false);
    };
    return {
      form,
      save,
      rules,
      formRef,
      closeModal,
      radioOptions: [
        {
          label: '推广传播',
          value: '1',
        },
        {
          label: '留资获客',
          value: '2',
        },
      ],
    };
  },
};
</script>
